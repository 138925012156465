<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ inbound.user_id }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ inbound.did_number }}
          </div>
          <div
            v-if="inbound.timeout_action === 'forward'"
            class="text-sm text-gray-500"
          >
            Forward to {{ inbound.forward_to_number ?? "N/A" }}
          </div>
          <div
            v-if="inbound.timeout_action === 'voicemail'"
            class="text-sm text-gray-500"
          >
            End Point {{ inbound.endpoint?.username ?? "N/A" }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="text-sm text-gray-900 max-w-xs truncate">
        {{ inbound.description }}
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <span
        class="
          px-2
          inline-flex
          text-xs
          leading-5
          font-semibold
          rounded-full
          bg-green-100
          text-green-800
        "
      >
        {{ inbound.ring_timeout }}
      </span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <span
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
        :class="
          inbound.direct_to_voicemail
            ? 'bg-green-100 text-green-800'
            : 'bg-red-100 text-red-800'
        "
      >
        {{ inbound.direct_to_voicemail ? "true" : "false" }}
      </span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ inbound.timeout_action }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
        :class="
          inbound.outreach
            ? 'bg-green-100 text-green-800'
            : 'bg-red-100 text-red-800'
        "
      >
        {{ inbound.outreach ? "true" : "false" }}
      </span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
      <a @click="edit" href="#" class="text-indigo-600 hover:text-indigo-900"
        >Edit</a
      >
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    inbound: {
      type: Object,
      default: null,
    },
  },
  emits: ["edit"],
  methods: {
    edit() {
      this.$emit("edit", this.inbound);
    },
  },
};
</script>
