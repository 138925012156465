<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden"
      @close="deleteOpen || restoreOpen ? null : $emit('close')"
      :open="open && !(deleteOpen || restoreOpen)"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      {{ this.isEdit ? "Edit" : "Add New" }} Inbound
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:outline-none
                        "
                        @click="$emit('close')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-2 relative flex-1">
                  <!-- Replace with your content -->
                  <div class="">
                    <form
                      class="h-full divide-y divide-gray-200 flex flex-col"
                      @submit="save"
                    >
                      <div class="flex-1 h-0 overflow-y-auto">
                        <div class="flex-1 flex flex-col justify-between">
                          <div class="px-4 divide-y divide-gray-200 sm:px-6">
                            <div class="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  for="project-name"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  DID Number *
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="didNumber"
                                    placeholder="123456789"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  for="location"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                  >Device *</label
                                >
                                <select
                                  v-model="selectedEndpoint"
                                  id="location"
                                  class="
                                    mt-1
                                    block
                                    w-full
                                    pl-3
                                    pr-10
                                    py-2
                                    text-base
                                    border-gray-300
                                    focus:outline-none
                                    focus:ring-indigo-500
                                    focus:border-indigo-500
                                    sm:text-sm
                                    rounded-md
                                  "
                                >
                                  <option value="0" disabled selected>
                                    SELECT
                                  </option>
                                  <option
                                    v-for="endpoint in endpoints"
                                    :value="endpoint.id"
                                    :key="endpoint.id"
                                  >
                                    {{ endpoint.username }}
                                  </option>
                                </select>
                              </div>
                              <div>
                                <label
                                  for="provider"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                  >Provider *</label
                                >
                                <select
                                  v-model="selectedProvider"
                                  id="provider"
                                  class="
                                    mt-1
                                    block
                                    w-full
                                    pl-3
                                    pr-10
                                    py-2
                                    text-base
                                    border-gray-300
                                    focus:outline-none
                                    focus:ring-indigo-500
                                    focus:border-indigo-500
                                    sm:text-sm
                                    rounded-md
                                  "
                                >
                                  <option value="0" disabled selected>
                                    SELECT
                                  </option>
                                  <option :value="'twilio'">Twilio</option>
                                  <option :value="'telnyx'">Telnyx</option>
                                </select>
                              </div>
                              <div v-if="timeoutAction === 'forward'">
                                <label
                                  for="project-name"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Forward to Number
                                  {{ timeoutAction === "forward" ? "*" : "" }}
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="forwardNumber"
                                    placeholder="123456789"
                                    type="text"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  for="description"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Description
                                </label>
                                <div class="mt-1">
                                  <textarea
                                    v-model="description"
                                    rows="4"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <h3 class="text-sm font-medium text-gray-900">
                                  Direct to voice mail
                                </h3>
                                <div class="mt-2">
                                  <div class="flex space-x-2">
                                    <Switch
                                      v-model="directToVoiceMail"
                                      :class="[
                                        directToVoiceMail
                                          ? 'bg-indigo-600'
                                          : 'bg-gray-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                                      ]"
                                    >
                                      <span class="sr-only">Use setting</span>
                                      <span
                                        aria-hidden="true"
                                        :class="[
                                          directToVoiceMail
                                            ? 'translate-x-5'
                                            : 'translate-x-0',
                                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                        ]"
                                      />
                                    </Switch>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <h3 class="text-sm font-medium text-gray-900">
                                  Outreach
                                </h3>
                                <div class="mt-2">
                                  <div class="flex space-x-2">
                                    <Switch
                                      v-model="outreach"
                                      :class="[
                                        outreach
                                          ? 'bg-indigo-600'
                                          : 'bg-gray-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                                      ]"
                                    >
                                      <span class="sr-only">Use setting</span>
                                      <span
                                        aria-hidden="true"
                                        :class="[
                                          outreach
                                            ? 'translate-x-5'
                                            : 'translate-x-0',
                                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                        ]"
                                      />
                                    </Switch>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  for="project-name"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  Ring timeout *
                                </label>
                                <div class="mt-1">
                                  <input
                                    type="number"
                                    v-model="ringTimeout"
                                    placeholder="30"
                                    class="
                                      block
                                      w-full
                                      shadow-sm
                                      sm:text-sm
                                      focus:ring-indigo-500
                                      focus:border-indigo-500
                                      border-gray-300
                                      rounded-md
                                    "
                                  />
                                </div>
                              </div>

                              <fieldset>
                                <legend
                                  class="text-sm font-medium text-gray-900"
                                >
                                  Timeout action
                                </legend>
                                <div class="mt-2 space-y-5">
                                  <div class="relative flex items-start">
                                    <div class="absolute flex items-center h-5">
                                      <input
                                        id="forward"
                                        name="privacy"
                                        aria-describedby="privacy-public-description"
                                        type="radio"
                                        class="
                                          focus:ring-indigo-500
                                          h-4
                                          w-4
                                          text-indigo-600
                                          border-gray-300
                                        "
                                        value="forward"
                                        v-model="timeoutAction"
                                      />
                                    </div>
                                    <div class="pl-7 text-sm">
                                      <label
                                        for="privacy-public"
                                        class="font-medium text-gray-900"
                                      >
                                        Forward
                                      </label>
                                      <p id="forward" class="text-gray-500">
                                        when timeout, foward the call.
                                      </p>
                                    </div>
                                  </div>
                                  <div class="relative flex items-start">
                                    <div class="absolute flex items-center h-5">
                                      <input
                                        id="voicemail"
                                        name="privacy"
                                        aria-describedby="privacy-public-description"
                                        type="radio"
                                        class="
                                          focus:ring-indigo-500
                                          h-4
                                          w-4
                                          text-indigo-600
                                          border-gray-300
                                        "
                                        value="voicemail"
                                        v-model="timeoutAction"
                                      />
                                    </div>
                                    <div class="pl-7 text-sm">
                                      <label
                                        for="voicemail"
                                        class="font-medium text-gray-900"
                                      >
                                        Voicemail
                                      </label>
                                      <p
                                        id="privacy-public-description2"
                                        class="text-gray-500"
                                      >
                                        when timeout, go to voice mail.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="errors.length !== 0" class="bg-red-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <XCircleIcon
                              class="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">
                              There were errors with your submission
                            </h3>
                            <div class="mt-2 text-sm text-red-700">
                              <ul class="list-disc pl-5 space-y-1">
                                <li v-for="error in errors" :key="error">
                                  {{ error }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="success" class="rounded-md bg-green-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <CheckCircleIcon
                              class="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-green-800">
                              {{ success }}
                            </h3>
                          </div>
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                        <button
                          @click="deleteOpen = true"
                          v-if="userType === 'admin' && isEdit && !isDeleted"
                          type="button"
                          class="
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            font-medium
                            rounded-md
                            text-red-700
                            bg-red-100
                            hover:bg-red-200
                            focus:outline-none
                          "
                        >
                          Delete
                        </button>
                        <button
                          @click="restoreOpen = true"
                          v-if="userType === 'admin' && isEdit && isDeleted"
                          type="button"
                          class="
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            font-medium
                            rounded-md
                            text-red-700
                            bg-red-100
                            hover:bg-red-200
                            focus:outline-none
                          "
                        >
                          Restore
                        </button>
                        <button
                          :disabled="loading"
                          type="submit"
                          class="
                            ml-4
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                          "
                        >
                          {{ loading ? "Wait.." : "Save" }}
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <InboundDelete :id="id ?? 0" :open="deleteOpen" @close="addClosed" />
  <InboundRestore :id="id ?? 0" :open="restoreOpen" @close="addClosed" />
</template>

<script>
import InboundDelete from "@/modules/inbounds/views/delete.vue";
import InboundRestore from "@/modules/inbounds/views/restore.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Switch,
} from "@headlessui/vue";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";
import { XIcon } from "@heroicons/vue/outline";
import inboundsApi from "@/network/refactored/inbounds.js";
import { mapActions } from "vuex";
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Switch,
    XCircleIcon,
    CheckCircleIcon,
    InboundDelete,
    InboundRestore,
  },

  emits: ["close"],

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: 0,
      isEdit: false,
      loading: false,
      errors: [],
      success: null,

      didNumber: "",
      forwardNumber: "",
      description: "",
      directToVoiceMail: false,
      ringTimeout: 30,
      timeoutAction: "forward",
      outreach: false,
      isDeleted: false,

      deleteOpen: false,
      restoreOpen: false,
      endpoints: [],
      selectedEndpoint: 0,
      selectedProvider: "telnyx",
    };
  },

  methods: {
    addClosed(removed) {
      this.deleteOpen = false;
      this.restoreOpen = false;
      if (removed) {
        this.$emit("close");
      }
    },

    async reset(data) {
      this.id = data?.id ?? 0;
      this.loading = false;
      this.errors = [];
      this.success = null;

      this.isEdit = !!data;

      this.didNumber = data?.did_number ?? "";
      this.forwardNumber = data?.forward_to_number ?? "";
      this.description = data?.description ?? "";
      this.selectedProvider = data?.service_type ?? "telnyx";

      this.directToVoiceMail = !!data?.direct_to_voicemail;
      this.outreach = !!data?.outreach;
      this.ringTimeout = data?.ring_timeout ?? 30;
      this.timeoutAction = data?.timeout_action ?? "forward";
      this.isDeleted = !!data?.is_deleted;

      this.selectedEndpoint = data?.endpoint?.id ?? 0;

      const response = await inboundsApi.listEndpoints();
      this.endpoints = response.data.data;
      this.listInbounds();
    },

    async save(e) {
      e.preventDefault();
      if (!this.validate()) return;

      this.loading = true;
      try {
        if (this.isEdit) {
          const { data } = await inboundsApi.update(
            this.id,
            this.didNumber,
            this.forwardNumber,
            this.description,
            this.directToVoiceMail,
            this.ringTimeout,
            this.timeoutAction,
            this.selectedEndpoint,
            this.selectedProvider,
            this.outreach
          );
          this.success = "Inbound updated successfully";
          this.updateInbound({ inbound: data });
        } else {
          const { data } = await inboundsApi.createInbound(
            this.didNumber,
            this.forwardNumber,
            this.description,
            this.directToVoiceMail,
            this.ringTimeout,
            this.timeoutAction,
            this.selectedEndpoint,
            this.selectedProvider,
            this.outreach
          );
          this.addInbound({ inbound: data });
          this.reset();
          this.success = "Inbound added successfully";
        }
      } catch (error) {
        if (error.response) {
          this.errors.push(error.response.data["message"]);
        } else {
          this.errors.push("Error while trying to submit data");
        }
      } finally {
        this.loading = false;
      }
    },

    validate() {
      this.errors = [];
      this.success = null;

      let isValid = true;
      if (this.didNumber === "") {
        isValid = false;
        this.errors.push("Enter a valid DID number");
      }

      if (this.timeoutAction === "forward" && this.forwardNumber === "") {
        isValid = false;
        this.errors.push("Enter a valid forward number");
      }

      if (this.selectedEndpoint === 0) {
        isValid = false;
        this.errors.push("Select a device");
      }

      if (this.ringTimeout === "" || this.ringTimeout <= 0) {
        isValid = false;
        this.errors.push("Enter a valid ring timeout");
      }

      return isValid;
    },

    ...mapActions("inbounds", ["addInbound", "updateInbound", "listInbounds"]),
  },

  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
  },
};
</script>
