<template>
  <nav
    class="bg-white px-3 py-3 flex items-center justify-between rounded-lg"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{
          (page - 1) * 10 === 0 ? 1 : (page - 1) * 10
        }}</span>
        to
        <span class="font-medium">{{ page * 10 }}</span>
        of
        <span class="font-medium">{{ totals }}</span>
        results
      </p>
    </div>
    <div class="flex-1 flex justify-between sm:justify-end">
      <a
        href="javascript:void(0)"
        @click="prev"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-1.5
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Previous
      </a>
      <a
        href="javascript:void(0)"
        @click="next"
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-1.5
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Next
      </a>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    prev() {
      if (this.page <= 1 || this.loading) return;
      this.updatePage({ page: this.page - 1 });
      this.listInbounds();
    },
    next() {
      if (this.page * 10 >= this.totals || this.loading) return;
      this.updatePage({ page: this.page + 1 });
      this.listInbounds();
    },
    ...mapActions("inbounds", ["listInbounds", "updatePage"]),
  },
  computed: {
    ...mapGetters("inbounds", ["page", "totals", "loading"]),
  },
};
</script>
