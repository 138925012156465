<template>
  <div class="flex flex-col mx-4">
    <div class="overflow-x-auto">
      <button
        @click="openAddInbound(null)"
        type="button"
        class="
          mb-1
          ml-auto
          relative
          block
          items-center
          px-3
          py-2
          border border-transparent
          shadow-sm
          text-sm
          rounded-md
          text-white
          bg-gray-700
          hover:bg-gray-800
          focus:outline-none
        "
      >
        Add Inbound
      </button>

      <div
        class="
          bg-white
          shadow
          sm:rounded-lg
          mx-4
          flex
          justify-between
          items-center
        "
      >
        <div class="w-full">
          <SectionHeader label="Filter" :loading="loading" />

          <form class="sm:flex sm:items-center px-3 pb-6">
            <div class="w-full">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-10">
                  <label class="block text-sm font-medium text-gray-700"
                    >Deleted Inbounds Only</label
                  >
                  <div class="mt-2">
                    <div class="flex space-x-2">
                      <Switch
                        v-model="deletedOnly"
                        :class="[
                          deletedOnly ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                        ]"
                      >
                        <span class="sr-only">Deleted Inbounds Only</span>
                        <span
                          aria-hidden="true"
                          :class="[
                            deletedOnly ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
                <div class="col-span-2">
                  <button
                    :disabled="loading"
                    @click="filter"
                    type="button"
                    class="
                      mt-6
                      h-9
                      w-full
                      inline-flex
                      items-center
                      justify-center
                      px-4
                      py-2
                      border border-transparent
                      shadow-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-indigo-600
                      hover:bg-indigo-700
                      focus:outline-none
                      sm:w-auto sm:text-sm
                    "
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  User ID
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Number
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Description
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  ring timeout
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  voicemail
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  timeout action
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  outreach
                </th>
                <th scope="col" class="relative px-6 py-3" />
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <InboundItem
                @edit="openAddInbound"
                v-for="inbound in inbounds"
                :key="inbound.id"
                :inbound="inbound"
              />
            </tbody>
          </table>

          <div v-if="inbounds.length === 0" class="mx-3 mb-2">
            <EmptyTable label="You do not have any inbounds." />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <Pagination />
    </div>
  </div>

  <AddInbound
    ref="addInbound"
    :open="addInboundOpened"
    @close="addInboundOpened = false"
  />
</template>

<script>
import InboundItem from "@/modules/inbounds/views/inbound_item.vue";
import Pagination from "@/modules/inbounds/views/inbounds_pagination.vue";
import AddInbound from "@/modules/inbounds/views/add_inbound.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";
import SectionHeader from "@/modules/common_views/section_header";
import { Switch } from "@headlessui/vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InboundItem,
    Pagination,
    EmptyTable,
    AddInbound,
    SectionHeader,
    Switch,
  },
  data() {
    return {
      addInboundOpened: false,
      deletedOnly: false,
    };
  },
  created() {
    this.listInbounds();
  },
  methods: {
    openAddInbound(data) {
      this.$refs.addInbound.reset(data);
      this.addInboundOpened = true;
    },
    filter() {
      if (this.loading) return;

      this.updateDeletedOnly({ deletedOnly: this.deletedOnly });

      this.listInbounds();
    },
    ...mapActions("inbounds", ["listInbounds", "updateDeletedOnly"]),
  },
  computed: {
    ...mapGetters("inbounds", ["inbounds", "loading"]),
  },
};
</script>
